import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import styled from 'styled-components'


const Courses = (props) => {

  return (
    <Shell >
      <Container>

        <h2 className="text-center font-bold text-lg md:text-3xl pt-10 pb-3 text-gray-800">Courses</h2>
        <p className="w-full md:w-1/3 text-center md:text-lg text-gray-500 mx-auto mb-10">Self-made courses around data visualization and data analysis</p>
      </Container>

      <Container>

        <div className="flex flex-row flex-wrap">

          <div className="mr-5 mb-10 bg-white rounded">
            <h3 className="mb-2 text-grey-800 text-base text-center">ggplot2 fundamentals</h3>
            <Link to="/courses/ggplot2fundamentals"><StaticImage src="../images/logos/logoggplot2fundamentals.png" placeholder="none" className="w-60 md:w-96 shadow hover:shadow-md" /></Link>
          </div>

        </div>
        
      </Container>

    </Shell>
  )
}

export default Courses


